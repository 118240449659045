import logo from 'assets/images/flowise_logo.png'
import logoDark from 'assets/images/flowise_logo.png'

import { useSelector } from 'react-redux'

// ==============================|| LOGO ||============================== //

const Logo = () => {
    const customization = useSelector((state) => state.customization)

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <img
                style={{ objectFit: 'contain', height: 'auto', width: 50, marginRight: 15 }}
                src={customization.isDarkMode ? logoDark : logo}
                alt='ChatDot'
            />
            <span style={{ color: '#804892', alignSelf: 'center', fontWeight: 'bold', fontSize: 22 }}>ChatDot</span>
        </div>
    )
}

export default Logo
